import { 
  getFirestore, 
  doc, 
  getDoc, 
  collection, 
  getDocs,
  query,
  orderBy,
  limit
} from 'firebase/firestore';

export const fetchSongData = async (id) => {
  const db = getFirestore();
  const songRef = doc(db, 'songs', id);
  const docSnap = await getDoc(songRef);

  if (docSnap.exists()) {
    const songData = docSnap.data();

    // Monta a query para pegar apenas o documento mais recente pela chave "updated"
    const songContentRef = collection(db, `songs/${id}/songs_content`);
    const songContentQuery = query(
      songContentRef,
      orderBy("updated", "desc"),
      limit(1)
    );

    const contentSnap = await getDocs(songContentQuery);
    const hasContent = !contentSnap.empty;
    const songContentDoc = hasContent ? contentSnap.docs[0] : null;
    const songContentData = hasContent ? songContentDoc.data() : null;
    const songContentDataId = hasContent ? songContentDoc.id : null;

    // Busca os detalhes da música na subcoleção 'songs_details'
    const songDetailsRef = collection(db, `songs/${id}/songs_details`);
    const detailsSnap = await getDocs(songDetailsRef);
    const songDetailsData = !detailsSnap.empty ? detailsSnap.docs[0].data() : null;

    return { songData, songContentData, songDetailsData, songContentDataId };
  }

  throw new Error('No such document!');
};
