// AuthRedirect.js

import React, { useEffect } from 'react';

const AuthRedirect = () => {
  useEffect(() => {
    // Obter a URL atual, incluindo parâmetros de consulta
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = url.search; // Inclui '?' e os parâmetros

     // Agora usa o redirecionamento correto esperado pelo Google
     const redirectUrl = `https://worshipcifras.com.br/auth-redirect${params}`;
    
     // Redireciona para a URL configurada no Google Cloud
     window.location.replace(redirectUrl);
     
    // Fallback: se o aplicativo não for aberto em X segundos, redirecionar para uma página alternativa
    setTimeout(() => {
      window.location.replace('https://worshipcifras.com.br/'); // Substitua pela URL desejada
    }, 3000); // 3 segundos
  }, []);

  return (
    <div>
      <p>Redirecionando para o aplicativo...</p>
    </div>
  );
};

export default AuthRedirect;
