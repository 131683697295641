import React, { useState, useEffect } from "react";
import { Box, Typography, Dialog, DialogTitle, DialogContent, Link } from "@mui/material";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../../firebase"; // Certifique-se de importar sua instância do Firestore

const AnnouncementsCarousel = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const navigate = useNavigate();

  // Busca os anúncios ativos e ordenados por ID no Firestore
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const q = query(
          collection(firestore, "home_news"),
          where("active", "==", true),
          orderBy("id", "asc")
        );
        const querySnapshot = await getDocs(q);
        const newsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAnnouncements(newsData);
      } catch (error) {
        console.error("Erro ao buscar anúncios:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleOpenDialog = (announcement) => {
    setSelectedAnnouncement(announcement);
  };

  const handleCloseDialog = () => {
    setSelectedAnnouncement(null);
  };

  const handleClick = (link) => {
    if (!link) return;

    const internalPrefix = "https://worshipcifras.com.br/song/";
    if (link.startsWith(internalPrefix)) {
      const songId = link.replace(internalPrefix, ""); // Extrai o ID da música
      navigate(`/song/${songId}`);
    } else {
      window.open(link, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Box sx={{ mt: 4, p: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
        Novidades
      </Typography>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          gap: 2,
          pb: 2,
          scrollSnapType: "x mandatory",
        }}
      >
        {announcements.map((announcement) => (
          <Box
            key={announcement.id}
            sx={{
              minWidth: 300,
              height: 300,
              borderRadius: 4,
              overflow: "hidden",
              cursor: "pointer",
              flexShrink: 0,
              border: "1px solid #222",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              scrollSnapAlign: "start",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
            onClick={() => handleOpenDialog(announcement)}
          >
            <img
              src={announcement.image}
              alt={announcement.title}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ))}
      </Box>

      <Dialog open={!!selectedAnnouncement} onClose={handleCloseDialog}>
        {selectedAnnouncement && (
          <>
            <DialogTitle>{selectedAnnouncement.title}</DialogTitle>
            <DialogContent>
              <Typography>{selectedAnnouncement.description}</Typography>
              {selectedAnnouncement.link && (
                <Typography sx={{ mt: 2 }}>
                  <Link
                    component="button"
                    onClick={() => handleClick(selectedAnnouncement.link)}
                    sx={{ cursor: "pointer", textDecoration: "underline", textDecorationColor: "inherit", color: "inherit" }}
                  >
                    Para saber mais, clique aqui.
                  </Link>
                </Typography>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default AnnouncementsCarousel;
