import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, IconButton, LinearProgress, Grid, Chip } from '@mui/material';
import { Instagram, Facebook, YouTube, Twitter } from '@mui/icons-material'; // Importar ícones das redes sociais
import { RiTiktokLine } from 'react-icons/ri';
import TopBar from '../common/TopBar';
import CardSection from '../common/CardSection';
import FooterNavbar from '../common/FooterNavbar';
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  getFirestore,
  where,
  doc,
  getDoc
} from 'firebase/firestore';
import { auth } from '../../firebase';
import LikedSongsSection from './utils/LikedSongsSection';
import AnnouncementsCarousel from './utils/AnnouncementsCarousel';

const Home = () => {
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore();
  const [xpData, setXpData] = useState(null);
  const [badgesData, setBadgesData] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(null);


  useEffect(() => {
    let isMounted = true;
    const fetchUserData = async (userId) => {
      if(!userId) return;

      setIsLoading(true);
      try {
        // Buscar XP e Nível
        const xpDocRef = doc(db, `users/${userId}/progress/xp`);
        const xpSnap = await getDoc(xpDocRef);

        let xpInfo = null;
        let levelName = "Carregando...";
        let badgesList = [];

        if (xpSnap.exists()) {
          xpInfo = xpSnap.data();
        }

         // Buscar nome do nível na coleção `levels`
         if (xpInfo.level) {
          const levelDocRef = doc(db, `levels/${xpInfo.level}`);
          const levelSnap = await getDoc(levelDocRef);

          if (levelSnap.exists()) {
            levelName = levelSnap.data().name;
          }
        }

        
        // Processar badges da string do XP
      if (xpInfo.badges) {
        const badgeNames = xpInfo.badges.split(",").map((badge) => badge.trim());

        // Buscar detalhes de cada badge na coleção `badges`
        const badgeDocs = await Promise.all(
          badgeNames.map(async (badgeName) => {
            if (badgeName) { // Verifica se não está vazio
              const badgeDocRef = doc(db, `badges/${badgeName}`); 
              const badgeSnap = await getDoc(badgeDocRef);
              return badgeSnap.exists() ? { id: badgeName, ...badgeSnap.data() } : null;
            }
            return null;
          })
        );

        badgesList = badgeDocs.filter((badge) => badge !== null);
      }

        setXpData({ ...xpInfo, levelName });
        setBadgesData(badgesList);

      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchSections = async (userId) => {
      setIsLoading(true);
      try {
        // Busca as seções definidas no Firestore
        const sectionsRef = collection(db, 'home_sections');
        const sectionsSnapshot = await getDocs(sectionsRef);
        let sectionsData = sectionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ordena as seções com base no campo 'order'
        sectionsData.sort((a, b) => a.order - b.order);

        const sectionsWithData = await Promise.all(
          sectionsData.map(async (section) => {
            const {
              collection: collectionName,
              order_by,
              order_direction,
              limit: sectionLimit,
              filters,
            } = section;

            let collectionRef = collection(db, collectionName);
            let q = query(collectionRef);

            if (filters && filters.length > 0) {
              filters.forEach((filter) => {
                q = query(q, where(filter.field, filter.operator, filter.value));
              });
            }

            if (order_by && order_direction) {
              q = query(q, orderBy(order_by, order_direction));
            }

            if (sectionLimit) {
              q = query(q, limit(sectionLimit));
            }

            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            return {
              ...section,
              data,
            };
          })
        );

        const songsRef = collection(db, 'songs');

        if (userId) {
          const recentSongsRef = collection(db, `users/${userId}/recent_songs`);
          const recentSongsQuery = query(
            recentSongsRef,
            orderBy('last_access_at', 'desc'),
            limit(10)
          );
          const recentSongsSnapshot = await getDocs(recentSongsQuery);

          const recentSongDocs = recentSongsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const recentSongIds = recentSongDocs.map((doc) => doc.id);

          if (recentSongIds.length > 0) {
            const chunkSize = 10;
            const chunks = [];
            for (let i = 0; i < recentSongIds.length; i += chunkSize) {
              chunks.push(recentSongIds.slice(i, i + chunkSize));
            }

            let songsData = [];
            for (const chunk of chunks) {
              const songsQuery = query(songsRef, where('__name__', 'in', chunk));
              const songsSnapshot = await getDocs(songsQuery);
              const chunkData = songsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              songsData = songsData.concat(chunkData);
            }

            const orderedSongsData = recentSongIds.map((id) =>
              songsData.find((song) => song.id === id)
            );

            const recentSongsSection = {
              id: 'recent_songs',
              title: 'Buscas recentes',
              type: 'square',
              item_type: 'song',
              data: orderedSongsData,
              order: 0,
            };

            sectionsWithData.unshift(recentSongsSection);
          }

          const likedSongsRef = collection(db, `users/${userId}/liked_songs`);
          const likedSongsSnapshot = await getDocs(likedSongsRef);

          if (!likedSongsSnapshot.empty) {
            const likedSongIds = likedSongsSnapshot.docs.map((doc) => doc.id);

            const chunkSize = 10;
            const chunks = [];
            for (let i = 0; i < likedSongIds.length; i += chunkSize) {
              chunks.push(likedSongIds.slice(i, i + chunkSize));
            }

            let likedSongsData = [];
            for (const chunk of chunks) {
              const likedSongsQuery = query(songsRef, where('__name__', 'in', chunk));
              const likedSongsDataSnapshot = await getDocs(likedSongsQuery);
              const chunkData = likedSongsDataSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              likedSongsData = likedSongsData.concat(chunkData);
            }

            const likedSongsSection = {
              id: 'liked_songs',
              title: 'Músicas curtidas',
              type: 'horizontal',
              item_type: 'song',
              data: likedSongsData,
              order: 1,
            };

            sectionsWithData.unshift(likedSongsSection);
          }
        }

        if (isMounted) {
          setSections(sectionsWithData);
        }
      } catch (error) {
        console.error('Error fetching home sections:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData(user.uid);
        fetchSections(user.uid);
      } else {
        setXpData(null);
        setBadgesData([]);
        fetchSections(null);
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [db]);

  return (
    <Box sx={{ pb: 7 }}>
      <TopBar />

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress />
          <Typography sx={{ marginTop: 2 }}></Typography>
        </Box>
      ) : (
        <>
          {/* Seção de XP e Level */}
          {xpData && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 3, mb: 0 }}>
            {/* Seção do nível do usuário */}
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="body2" sx={{ color: "gray", fontSize: "12px" }}>
                Level
              </Typography>
              <Typography variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "bold" }}>
                {xpData?.levelName || "Carregando..."}
              </Typography>
            </Box>
          
            {/* Barra de progresso ao lado */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box
                sx={{
                  width: "40vw",
                  height: "20px",
                  borderRadius: "8px",
                  backgroundColor: "#444",
                  position: "relative",
                  overflow: "hidden"
                }}
              >
                <Box
                  sx={{
                    width: `${(xpData.current / xpData.target) * 100}%`,
                    height: "100%",
                    backgroundColor: "#a52422",
                    borderRadius: "8px",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "5px",
                    transform: "translateY(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  {xpData.current} xp
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "5px",
                    transform: "translateY(-50%)",
                    fontSize: "10px",
                    color: "grey",
                    fontWeight: "bold"
                  }}
                >
                  {xpData.target}
                </Typography>
              </Box>
            </Box>
          </Box>
          
          )}

          {/* Seção de Conquistas (Badges) */}
          {badgesData.length > 0 && (
            <Box sx={{ p: 3, mt: -5, mb: -5 }}>
            <Typography variant="body2" sx={{ color: "gray", fontSize: "12px",}}>
              Conquistas
            </Typography>
          
            <Box sx={{ display: "flex", overflowX: "auto", gap: 1, mt: 1, pb: 2, }}>
              {badgesData.map((badge) => (
                <Box
                  key={badge.id}
                  sx={{
                    flexShrink: 0,
                    textAlign: "center",
                    cursor: "pointer"
                  }}
                  onClick={() => setSelectedBadge(badge)}
                >
                  <img
                    src={badge.image}
                    alt={badge.name}
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                  />                  
                </Box>
              ))}
            </Box>
          </Box>
          )}

          {selectedBadge && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10
              }}
              onClick={() => setSelectedBadge(null)}
            >
              <Box
                sx={{
                  backgroundColor: "#222",
                  padding: 3,
                  borderRadius: 2,
                  textAlign: "center",
                  maxWidth: "80vw"
                }}
                onClick={(e) => e.stopPropagation()} // Evita fechar ao clicar dentro do modal
              >
                <img
                  src={selectedBadge.image}
                  alt={selectedBadge.name}
                  style={{ width: 150, height: 150, borderRadius: "50%" }}
                />
                <Typography variant="h6" sx={{ color: "white", mt: 2 }}>
                  {selectedBadge.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                  {selectedBadge.description}
                </Typography>
              </Box>
            </Box>
          )}


          <AnnouncementsCarousel />
          {sections.map((section) => {
            if (section.id === 'liked_songs') {
              return <LikedSongsSection key={section.id} data={section.data} />;
            }
            return (
              <CardSection
                key={section.id}
                title={section.title}
                type={section.type}
                data={section.data}
                itemType={section.item_type}
              />
            );
          })}
        </>
      )}

      {/* Nova seção de redes sociais */}
      <Box sx={{ mt: 4, p: 2, textAlign: 'left' }}>
        <Typography variant="body1" sx={{ color: 'grey' }}>
          Siga o Worship Cifras
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'left', mt: 1, gap: 2 }}>
          <IconButton
            component="a"
            href="https://www.tiktok.com/@worship.cifras"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <RiTiktokLine  />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.instagram.com/worshipcifras"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <Instagram />
          </IconButton>          
          <IconButton
            component="a"
            href="https://youtube.com/@worshipcifras6458?si=A6quQanD54dQK44S"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <YouTube />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.facebook.com/worshipcifras"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <Facebook />
          </IconButton>
          <IconButton
            component="a"
            href="https://x.com/CifrasWorship"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <Twitter />
          </IconButton>
        </Box>
      </Box>

      <FooterNavbar />
    </Box>
  );
};

export default Home;
