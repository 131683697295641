// SignUp.js
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { GoogleIcon, WorshipCifrasIcon } from './CustomIcons';
import { 
  createUserWithEmailAndPassword, 
  updateProfile, 
  sendEmailVerification, 
  GoogleAuthProvider,
  getAuth,
  signInWithCredential, 
} from 'firebase/auth';
import { auth, firestore } from '../../../firebase';
import { doc, setDoc, getDoc, collection, serverTimestamp } from 'firebase/firestore';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useGoogleLogin } from "@react-oauth/google";

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: 4,
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  ...theme.applyStyles('dark', {
    backgroundImage:
      'radial-gradient(at 50% 50%, hsl(0, 0%, 20%), hsl(0, 0%, 5%))',
  }),
}));

export default function SignUp() {
  const navigate = useNavigate(); // Para navegar para outras páginas
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [termsError, setTermsError] = React.useState(false);
  // Snackbar para exibir mensagens de feedback
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success'); // 'success', 'error', 'warning', 'info'

  // Estado para controlar a visibilidade da senha
  const [showPassword, setShowPassword] = React.useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');
    const name = document.getElementById('name');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Por favor, digite um email válido.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Sua senha precisa ter no mínimo 6 caracteres.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!name.value || name.value.length < 1) {
      setNameError(true);
      setNameErrorMessage('O nome é obrigatório.');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    if (!termsAccepted) {
      setTermsError(true);
      isValid = false;
    } else {
      setTermsError(false);
    }

    return isValid;
  };

  const initializeUserProgress = async (userId, db) => {
    try {
      // Referência à subcoleção "progress"
      const progressRef = collection(db, `users/${userId}/progress`);
  
      // Criar documento "xp"
      const xpRef = doc(progressRef, "xp");
      await setDoc(xpRef, {
        current: 50,
        level: 1,
        target: 500,
        badges: "1_welcome_silver", // Nenhum badge inicial
      });
  
      // Criar documento "badges_xp"
      const badgesXpRef = doc(progressRef, "badges_xp");
      await setDoc(badgesXpRef, {
        "1_welcome_silver": "1",
        "2_register-completed_gold": "0",
        "3_visited-chords_silver": "0/10",
        "3_visited-chords_gold": "0/50",
        "3_visited-chords_ruby": "0/100",
        "4_liked-chords_silver": "0/10",
        "4_liked-chords_gold": "0/50",
        "4_liked-chords_ruby": "0/100",
        "5_youtube-chords_silver": "0/10",
        "5_youtube-chords_gold": "0/50",
        "5_youtube-chords_ruby": "0/100"
      });
  
      // Criar documento "actions" e subcoleção "taken"
      const actionsRef = doc(progressRef, "actions");
      await setDoc(actionsRef, {}); // Documento principal vazio

      // Criar o primeiro registro na subcoleção "taken"
      const takenRef = collection(actionsRef, "taken");
      const firstActionRef = doc(takenRef, Date.now().toString()); // ID baseado no timestamp atual

      await setDoc(firstActionRef, {
        actionType: "First Access",
        created: serverTimestamp(),
        xpGained: 50
      });
  
      console.log("Progressão do usuário inicializada com sucesso!");
    } catch (error) {
      console.error("Erro ao inicializar progressão do usuário:", error);
    }
  };
  


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateInputs()) {
      return; // Retorna se a validação falhar
    }

    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const name = data.get('name');

    try {
      // Cria um novo usuário com email e senha
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Atualizar o perfil do usuário com o nome
      await updateProfile(user, {
        displayName: name,
      });

      // Enviar email de verificação
      await sendEmailVerification(user);

      // Cria um documento na coleção 'users' com o UID do usuário
      await setDoc(doc(firestore, 'users', user.uid), {
        name: name,
        email: email,
        phone_number: '',
        birth_date: '',
        nationality: '',
        current_location: '',
        short_bio: '',
        subscription: 'free',
        role: 'user',
        createdAt: new Date(),
      });
      
      // Criando as subcollections da Gamefication
      await initializeUserProgress(user.uid, firestore);

      // Notificar o usuário sobre o email de verificação
      setSnackbarMessage('Um email de verificação foi enviado para ' + email);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      // Redireciona o usuário para a página desejada após o cadastro
      navigate('/verify-email');
    } catch (error) {
      console.error('Erro ao criar conta:', error);
      if (error.code === 'auth/email-already-in-use') {
        setEmailError(true);
        setEmailErrorMessage('Este email já está em uso.');
      } else {
        setEmailError(true);
        setEmailErrorMessage('Ocorreu um erro ao criar a conta.');
      }
    }
  };

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        console.log("Token recebido:", tokenResponse);
        
        const idToken = tokenResponse.access_token; // Google retorna access_token aqui
        if (!idToken) throw new Error("Erro ao obter o token do Google.");
  
        // Criar credencial do Firebase com idToken
        const auth = getAuth();
        const credential = GoogleAuthProvider.credential(null, idToken);
        const firebaseResult = await signInWithCredential(auth, credential);
  
        const user = firebaseResult.user;
        console.log("Usuário autenticado no Firebase:", user);
  
        // Verifica se o usuário já existe no Firestore
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            name: user.displayName || 'Usuário',
            email: user.email,
            phone_number: '',
            birth_date: '',
            nationality: '',
            current_location: '',
            short_bio: '',
            subscription: 'free',
            role: 'user',
            createdAt: new Date(),
        });

        console.log("Novo usuário criado na coleção Firestore.");

        // Criando as subcollections da Gamificação
        await initializeUserProgress(user.uid, firestore);
        }
  
        navigate("/");
      } catch (error) {
        console.error("Erro ao autenticar com Firebase:", error);
      }
    },
    onError: (error) => console.error("Erro ao autenticar com Google:", error),
  });

  const handleBack = () => {
    navigate(-1); // Volta para a página anterior
  };

  return (
    <SignUpContainer direction="column" justifyContent="space-between">
      <CssBaseline enableColorScheme />

      {/* Barra de Navegação - Adicionando a parte de "Voltar" */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, marginTop: 2 }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Voltar
        </Typography>
      </Box>

      <Stack
        sx={{
          justifyContent: 'center',
          height: '100dvh',
          p: 2,
        }}
      >
        <Card variant="outlined">
          <WorshipCifrasIcon />
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Cadastre-se
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="name">Nome completo</FormLabel>
              <TextField
                autoComplete="name"
                name="name"
                required
                fullWidth
                id="name"
                placeholder="Gustavo Fring"
                error={nameError}
                helperText={nameErrorMessage}
                color={nameError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="seu@email.com"
                name="email"
                autoComplete="email"
                variant="outlined"
                error={emailError}
                helperText={emailErrorMessage}
                color={passwordError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Senha</FormLabel>
              <TextField
                required
                fullWidth
                name="password"
                placeholder="••••••"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="new-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? 'error' : 'primary'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Alternar visibilidade da senha"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value="allowExtraEmails"
                  color="primary"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
              }
              label={
                <>
                  Eu concordo com os{' '}
                  <Link href="/termos-de-uso" target="_blank">
                    termos de uso
                  </Link>{' '}
                  do Worship Cifras.
                </>
              }
            />
            {termsError && (
              <Typography variant="caption" color="error">
                Você deve aceitar os termos de uso para se cadastrar.
              </Typography>
            )}

            <Button type="submit" fullWidth variant="contained">
              Criar conta
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
              Já possui uma conta?{' '}
              <span>
                <Link
                  href="/signin"
                  variant="body2"
                  sx={{ alignSelf: 'center' }}
                >
                  Entre agora.
                </Link>
              </span>
            </Typography>
          </Box>          
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>ou</Typography>
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              onClick={handleGoogleSignIn}
              startIcon={<GoogleIcon />}
            >
              Entrar com o Google
            </Button>
          </Box>          
        </Card>        
      </Stack>
      <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
    </Snackbar>
    </SignUpContainer>
  );
}
