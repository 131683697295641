import { doc, getDoc, updateDoc, collection, addDoc, serverTimestamp, getFirestore } from "firebase/firestore";
import emailjs from "emailjs-com";

class XPManager {
  constructor() {
    this.db = getFirestore(); // 🔥 Firestore inicializado automaticamente
  }

  async addXP(userId, actionType, xpGained, type = null) {
    try {
      const progressRef = collection(this.db, `users/${userId}/progress`);
  
      // 📌 1. Registrar ação em /actions/taken
      const actionsRef = collection(progressRef, "actions/taken");
      await addDoc(actionsRef, {
        actionType,
        created: serverTimestamp(),
        xpGained
      });
  
      // 📌 2. Atualizar XP do usuário
      const xpRef = doc(progressRef, "xp");
      const xpSnap = await getDoc(xpRef);
  
      if (!xpSnap.exists()) throw new Error("XP document not found for user");
  
      let xpData = xpSnap.data();
      let newXP = xpData.current + xpGained;
      let newBadges = xpData.badges ? xpData.badges.split(",") : [];
  
      // 📌 3. Atualizar XP e verificar mudança de nível
      const levelUp = newXP >= xpData.target;
      let newLevel = xpData.level;
      let newTarget = xpData.target;
  
      if (levelUp) {
        newLevel++;
        newTarget += 500;
      }
  
      await updateDoc(xpRef, {
        current: newXP,
        level: newLevel,
        target: newTarget,
        badges: newBadges.join(",")
      });
  
      // 📌 4. Atualizar badges_xp baseado no tipo de ação
      if (type) {
        const badgesXpRef = doc(progressRef, "badges_xp");
        const badgesSnap = await getDoc(badgesXpRef);
  
        if (badgesSnap.exists()) {
          let badgesData = badgesSnap.data();
          let updatedBadges = [];
  
          // Definir os campos corretos baseados no tipo da ação
          let badgeKeys = {
            visit: ["3_visited-chords_silver", "3_visited-chords_gold", "3_visited-chords_ruby"],
            like: ["4_liked-chords_silver", "4_liked-chords_gold", "4_liked-chords_ruby"],
            youtube: ["5_youtube-chords_silver", "5_youtube-chords_gold", "5_youtube-chords_ruby"]
          };
  
          if (badgeKeys[type]) {
            for (const badgeKey of badgeKeys[type]) {
              if (badgesData[badgeKey] && badgesData[badgeKey].includes("/")) {
                let [current, target] = badgesData[badgeKey].split("/").map(Number);
                
                if (current < target) {
                  current += 1; // Soma +1 ao valor atual, sem ultrapassar o target
                  badgesData[badgeKey] = `${current}/${target}`;
  
                  // Se atingiu o target, marca como conquistado
                  if (current >= target) {
                    updatedBadges.push(badgeKey);
                  }
                }
              }
            }
  
            // Atualizar os badges no Firestore
            await updateDoc(badgesXpRef, badgesData);
          }
  
          // 📌 5. Se houver novos badges conquistados, adicionar ao XP e enviar email
          if (updatedBadges.length > 0) {
            newBadges.push(...updatedBadges);
            await updateDoc(xpRef, {
              badges: newBadges.join(",")
            });
  
            // 📌 6. Enviar email de conquista
            // Nao vou enviar email ainda, pois teria que elevar meu plano no EmailJS para um novo template
            // Talvez vale adaptar um template para um uso mais generico no EmailJS
            //await this.sendCongratulatoryEmail(userId, newEarnedBadges, levelUp);
          }
        }
      }
    } catch (error) {
      console.error("Erro ao processar XP:", error);
    }
  }
  

  async sendCongratulatoryEmail(userId, badges, leveledUp) {
    try {
      const userRef = doc(this.db, `users/${userId}`);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) return;

      const userData = userSnap.data();
      const email = userData.email;

      let message = `Parabéns, ${userData.name}! `;
      if (leveledUp) message += `Você subiu de nível! 🎉 `;
      if (badges.length > 0) message += `Você conquistou novos emblemas: ${badges.join(", ")} 🏅`;

      const emailParams = {
        to_email: email,
        to_name: userData.name,
        message
      };

      await emailjs.send("service_id", "template_id", emailParams, "user_api_key");
      console.log(`Email enviado para ${email}`);
    } catch (error) {
      console.error("Erro ao enviar email:", error);
    }
  }
}

export default XPManager;
